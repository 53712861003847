<template>
  <article
    class="bg-static-info-mid rounded-lg flex max-w-[736px] flex-col md:flex-row"
  >
    <div
      class="mx-8 my-56 flex flex-col justify-center sm:mx-48 md:justify-start"
    >
      <h3 class="heading-1 text-center md:text-left">
        {{ i18n(translations.confirmationAppsTitle) }}
      </h3>
      <p class="body-1-bold mt-8 text-center md:text-left">
        {{ i18n(translations.confirmationAppsDescription) }}
      </p>
      <AppStoreButtons
        campaign="buyback_confirmation"
        class="mt-32 flex justify-center md:justify-start"
        tracking-zone="trade_in"
      />
    </div>
    <div
      class="relative mt-auto flex h-[214px] w-full max-md:justify-center md:max-w-[320px]"
    >
      <RevIllustration
        alt="phone"
        class="absolute bottom-0"
        data-test="mobileAppImg"
        :height="214"
        src="/img/recycling/phoneBanner.svg"
        :width="154"
      />
    </div>
  </article>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'

import translations from './MobileAppBanner.translations'

const i18n = useI18n()
</script>
