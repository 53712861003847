<template>
  <div v-if="shouldDisplayStores">
    <RevButtonBase
      target="_blank"
      :to="getStoreUrl(googleStoreBaseUrl)"
      :tracking="{
        name: 'redirect_google_play',
        zone: trackingZone,
      }"
    >
      <RevIllustration
        alt="Google Play"
        class="inline-block h-[4rem]"
        :height="40"
        :src="`/img/socials/${market.countryCode}/google-play.svg`"
        :width="135"
      />
    </RevButtonBase>

    <RevButtonBase
      target="_blank"
      :to="getStoreUrl(appleStoreBaseUrl)"
      :tracking="{
        name: 'redirect_app_store',
        zone: trackingZone,
      }"
    >
      <RevIllustration
        alt="Apple Store"
        class="ml-2 inline-block h-[4rem]"
        :height="40"
        :src="`/img/socials/${market.countryCode}/apple-store.svg`"
        :width="127"
      />
    </RevButtonBase>
  </div>
</template>

<script lang="ts" setup>
import { useRuntimeConfig } from '#imports'
import { computed } from 'vue'

import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevIllustration } from '@ds/components/Illustration'

import {
  APPLE_STORE_REDIRECT_URL,
  APPSFLYER_CAMPAIGN,
  APPSFLYER_MEDIA_SOURCE,
  GOOGLE_STORE_REDIRECT_URL,
  STORE_COUNTRIES,
} from './AppStoreButtons.constant'

// Note that before registering new URLs to this configuration, you should
// also download and store associated assets in `public/img/social`.

// assets may be found here: https://www.figma.com/file/jC2mtHRgj5YEWRLSQYfKHq/04.-Assets?node-id=1%3A852

type AppStoreButtonsProps = {
  campaign?: string
  trackingZone: string
}

const props = defineProps<AppStoreButtonsProps>()
const { market } = useMarketplace()
const runtimeConfig = useRuntimeConfig()

const { APPSFLYER_BASE_URL } = runtimeConfig.public

const storesBaseUrl = `${APPSFLYER_BASE_URL}?pid=${APPSFLYER_MEDIA_SOURCE}&c=${APPSFLYER_CAMPAIGN}`

const appleStoreBaseUrl = `${storesBaseUrl}&af_r=${APPLE_STORE_REDIRECT_URL}`
const googleStoreBaseUrl = `${storesBaseUrl}&af_r=${GOOGLE_STORE_REDIRECT_URL}`

function getStoreUrl(storeBaseUrl: string) {
  return `${storeBaseUrl}&af_ad=${market.countryCode.toLowerCase()}&af_adset=${
    props.campaign
  }`
}

const shouldDisplayStores = computed(() =>
  STORE_COUNTRIES.includes(market.countryCode),
)
</script>
