import { MarketCountryCode } from '@backmarket/http-api'

export const STORE_COUNTRIES = [
  MarketCountryCode.AT,
  MarketCountryCode.BE,
  MarketCountryCode.DE,
  MarketCountryCode.ES,
  MarketCountryCode.FI,
  MarketCountryCode.FR,
  MarketCountryCode.GR,
  MarketCountryCode.IT,
  MarketCountryCode.NL,
  MarketCountryCode.GB,
  MarketCountryCode.IE,
  MarketCountryCode.US,
  MarketCountryCode.PT,
  MarketCountryCode.GR,
  MarketCountryCode.SK,
]

export const GOOGLE_STORE_REDIRECT_URL =
  'https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.backmarket'
export const APPLE_STORE_REDIRECT_URL =
  'https%3A%2F%2Fapps.apple.com%2Fapp%2Fback-market-achat-vente%2Fid1458344336'

export const APPSFLYER_MEDIA_SOURCE = 'backmarket'
export const APPSFLYER_CAMPAIGN = 'stores'
